// external Dependencies
import React from 'react';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import BuildIcon from '@material-ui/icons/Build';
import AddBoxIcon from '@material-ui/icons/AddBox';
import LanguageIcon from '@material-ui/icons/Language';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

// gatsby Dependencies
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

// internal Dependencies
import Section from '../components/shared/Section';
import SEO from '../components/SEO';
import Card from '../components/shared/Card';
import Video from '../components/shared/Video';
import Button from '../components/shared/Button';
import Cta from '../components/shared/Cta';
import TwoColTextAndImage from '../components/shared/TwoColTextAndImage';
import EnhancedGrid from '../components/shared/EnhancedGrid';

const StyledWrapper = styled.div`
  background: rgb(255, 255, 255);
  background: linear-gradient(
    360deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(247, 241, 240, 1) 80%
  );
`;

const StyledCarousel = styled.div`
  height: 75vh;
  min-height: 500px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;

  @-webkit-keyframes carouselFadeInOut {
    0% {
      opacity: 1;
    }
    17% {
      opacity: 1;
    }
    25% {
      opacity: 0;
    }
    92% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes carouselFadeInOut {
    0% {
      opacity: 1;
    }
    17% {
      opacity: 1;
    }
    25% {
      opacity: 0;
    }
    92% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes carouselFadeInOut {
    0% {
      opacity: 1;
    }
    17% {
      opacity: 1;
    }
    25% {
      opacity: 0;
    }
    92% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes carouselFadeInOut {
    0% {
      opacity: 1;
    }
    17% {
      opacity: 1;
    }
    25% {
      opacity: 0;
    }
    92% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  #carousel-wrapper {
    position: absolute;
    z-index: -1;
    height: 75vh;
    width: 100vw;
  }
  #carousel-wrapper div.gatsby-image-wrapper {
    position: absolute;
    left: 0;
  }

  #carousel-wrapper div.gatsby-image-wrapper {
    -webkit-animation-name: carouselFadeInOut;
    -webkit-animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-duration: 20s;

    -moz-animation-name: carouselFadeInOut;
    -moz-animation-timing-function: ease-in-out;
    -moz-animation-iteration-count: infinite;
    -moz-animation-duration: 20s;

    -o-animation-name: carouselFadeInOut;
    -o-animation-timing-function: ease-in-out;
    -o-animation-iteration-count: infinite;
    -o-animation-duration: 20s;

    animation-name: carouselFadeInOut;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 20s;
  }
  #carousel-wrapper div.gatsby-image-wrapper:nth-of-type(1) {
    -webkit-animation-delay: 15s;
    -moz-animation-delay: 15s;
    -o-animation-delay: 15s;
    animation-delay: 15s;
  }
  #carousel-wrapper div.gatsby-image-wrapper:nth-of-type(2) {
    -webkit-animation-delay: 10s;
    -moz-animation-delay: 10s;
    -o-animation-delay: 10s;
    animation-delay: 10s;
  }
  #carousel-wrapper div.gatsby-image-wrapper:nth-of-type(3) {
    -webkit-animation-delay: 5s;
    -moz-animation-delay: 5s;
    -o-animation-delay: 5s;
    animation-delay: 5s;
  }
  #carousel-wrapper div.gatsby-image-wrapper:nth-of-type(4) {
    -webkit-animation-delay: 0;
    -moz-animation-delay: 0;
    -o-animation-delay: 0;
    animation-delay: 0;
  }
`;

const StyledTextWrapper = styled.div`
  width: 100%;
  text-align: center;

  h1,
  h5 {
    color: white;
    max-width: 75%;
    margin: 1rem auto;

    @media (max-width: 600px) {
      max-width: 90%;
    }
  }

  h5 {
    text-transform: uppercase;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    letter-spacing: 1px;
  }

  h5:before,
  h5:after {
    content: '';
    border-top: 4px solid;
    margin: 0 10px;
    flex: 0 0 40px;
  }

  button {
    margin: auto;
  }
`;

const StyledOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${(props) => props.theme.colors.blue};
  opacity: 0.1;
  z-index: 1;
`;

// component definition
const HomePage = () => {
  const data = useStaticQuery(graphql`
    query {
      page: contentfulHomePage {
        id
        ctaSection {
          email
          headline
          phoneNumber
          supertext
        }
        metaDescription
        pageTitle
        slug
        hero {
          buttonLink
          buttonText
          headline
          supertext
          image {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
        benefitsSection {
          threeColumnContainerHeadline
          threeColumnGridButtonLink
          threeColumnGridButtonText
          modules {
            cardBody {
              cardBody
            }
            cardTitle
          }
        }
        threeCardGrid {
          threeColumnContainerHeadline
          threeColumnGridButtonLink
          threeColumnGridButtonText
          modules {
            cardBody {
              cardBody
            }
            cardTitle
          }
        }
        video {
          file {
            url
          }
        }
        videoPoster {
          file {
            url
          }
        }
        twoColumnTextAnImage {
          bodyCopy {
            bodyCopy
          }
          buttonLink
          buttonText
          supertext
          reverseOrder
          title
          image {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            title
          }
        }
        carouselImages {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          title
          description
        }
      }
    }
  `);

  const heroImages = data.page.carouselImages.map((image, i) => (
    <GatsbyImage
      key={`image-${i}`}
      image={image.gatsbyImageData}
      style={{ width: '100vw', height: '75vh' }}
      alt={image.description}
    />
  ));

  const threeColGridCards = data.page.threeCardGrid.modules.map((card, i) => {
    let icon =
      i === 0 ? (
        <ViewWeekIcon
          key={i}
          fontSize='large'
          viewBox='0 0 24 24'
          style={{ maxWidth: '35px', maxHeight: '35px' }}
          htmlColor='#ebaa20'
        />
      ) : i === 1 ? (
        <BuildIcon
          key={i}
          fontSize='large'
          viewBox='0 0 24 24'
          style={{ maxWidth: '35px', maxHeight: '35px' }}
          htmlColor='#ebaa20'
        />
      ) : i === 2 ? (
        <LanguageIcon
          key={i}
          fontSize='large'
          viewBox='0 0 24 24'
          style={{ maxWidth: '35px', maxHeight: '35px' }}
          htmlColor='#ebaa20'
        />
      ) : null;

    return (
      <Grid item xs={12} sm={12} md={4}>
        <Card
          key={i}
          headline={card.cardTitle}
          body={card.cardBody.cardBody}
          icon={icon}
        />
      </Grid>
    );
  });

  const threeColGridItems = data.page.benefitsSection.modules.map((card, i) => (
    <Grid item xs={12} sm={6} md={4} key={i}>
      <Card icon={<AddBoxIcon />} small headline={card.cardTitle} />
    </Grid>
  ));

  const content = (
    <StyledTextWrapper>
      <h1>Concrete Never Looked So Good</h1>
      <Button buttonColor='gold' link='https://concretefence.concora.com/'>
        SEE OUR DESIGN CENTER
      </Button>
    </StyledTextWrapper>
  );

  return (
    <StyledWrapper>
      <SEO
        description={data.page.metaDescription}
        title={data.page.pageTitle}
      />
      <StyledCarousel>
        <StyledOverlay />
        {content}
        <div id='carousel-wrapper'>{heroImages}</div>
      </StyledCarousel>

      <Section dense shiftUp style={{ zIndex: 1 }}>
        <EnhancedGrid spacing={2}>{threeColGridCards}</EnhancedGrid>
      </Section>
      <TwoColTextAndImage
        image={data.page.twoColumnTextAnImage.image}
        superText={data.page.twoColumnTextAnImage.supertext}
        headline={data.page.twoColumnTextAnImage.title}
        body={data.page.twoColumnTextAnImage.bodyCopy.bodyCopy}
        buttonText={data.page.twoColumnTextAnImage.buttonText}
        buttonLink={data.page.twoColumnTextAnImage.buttonLink}
        altText={data.page.twoColumnTextAnImage.image.title}
      />
      <Section>
        <Video
          headline='Why Choose Superior Concrete Products'
          videoSrc={data.page.video.file.url}
          videoPoster={data.page.videoPoster.file.url}
        />
      </Section>
      <div>
        <Section textCenter>
          <h3>{data.page.benefitsSection.threeColumnContainerHeadline}</h3>
          <EnhancedGrid
            alignItems='flex-start'
            justifyContent='center'
            spacing={1}
            style={{ margin: '25px auto' }}
          >
            {threeColGridItems}
          </EnhancedGrid>
          <Button
            blue
            center
            link={data.page.benefitsSection.threeColumnGridButtonLink}
          >
            {data.page.benefitsSection.threeColumnGridButtonText}
          </Button>
        </Section>
      </div>
      <Section textCenter>
        <h4>
          Interested in joining our team? Please submit your resume to <a href="mailto: hr@concretefence.com">hr@concretefence.com</a>.
        </h4>
      </Section>
      <Cta
        ctaSupertext={data.page.ctaSection.supertext}
        ctaHeadline={data.page.ctaSection.headline}
        phoneNumber={data.page.ctaSection.phoneNumber}
        email={data.page.ctaSection.email}
        form
      />
    </StyledWrapper>
  );
};

export default HomePage;
