// external dependencies
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledCardWrapper = styled.div`
  display: flex;
  justify-content: ${(props) => (props.small ? 'flex-start' : 'center')};
  align-items: ${(props) => (props.small ? 'center' : 'flex-start')};
  background-color: ${(props) => (props.small ? '' : props.theme.colors.white)};
  text-align: ${(props) => (props.small ? 'left' : 'center')};
  min-height: ${(props) => (props.small ? 'auto' : '250px')};
  width: 100%;
  box-shadow: ${(props) => (props.small ? '' : props.theme.effects.boxShadow)};
  padding: ${(props) => (props.small ? '15px' : '25px')};
  margin: 10px auto;
  border-radius: ${(props) => props.theme.elements.borderRadius};

  @media (max-width: 600px) {
    text-align: ${(props) => (props.small ? 'left' : 'center')};
    padding: ${(props) => (props.small ? '0' : '15px')};
  }

  div {
    height: auto;
  }

  h5 {
    font-size: 1rem;
    color: ${(props) => props.theme.colors.blue};
    text-transform: uppercase;
    margin-top: ${(props) => (props.small ? 0 : '1rem')};
  }

  span {
    margin-right: ${(props) => (props.small ? '10px' : '0px')};
  }

  @media (max-width: 959px) {
    align-items: center;
  }
`;

// component definition
const Card = ({ headline, body, icon, small }) => {
  return (
    <StyledCardWrapper small={small}>
      {small ? (
        <>
          <span>{icon}</span>
          <div>
            <h5>{headline}</h5>
            {!small && <p>{body}</p>}
          </div>
        </>
      ) : (
        <div>
          <span>{icon}</span>
          <h5>{headline}</h5>
          {!small && <p>{body}</p>}
        </div>
      )}
    </StyledCardWrapper>
  );
};

export default Card;
